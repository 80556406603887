<template>
  <div class="page-container">
    <page-header></page-header>
    <div class="dashboard-container">
      <div class="page-card">
        <div class="page-card-boody" v-if="deUser" style="padding: 20px;">
          <div class="descriptions">
            <div class="descriptions-header">
              <div class="descriptions-title">划转用户信息</div>
            </div>
            <div class="descriptions-view">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">发电户号</span>
                      <span class="descriptions-item-content">{{ deUser.generatorNo }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">开发商</span>
                      <span class="descriptions-item-content">{{ deUser.developersId }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">开发商名称</span>
                      <span class="descriptions-item-content">{{ deUser.developers.name }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">经销商</span>
                      <span class="descriptions-item-content">{{ deUser.distributorId }}</span>
                    </div>
                  </td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">经销商名称</span>
                      <span class="descriptions-item-content">
                        {{ deUser.distributor.name }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">用户姓名</span>
                      <span class="descriptions-item-content">
                        {{ deUser.name }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">身份证号</span>
                      <span class="descriptions-item-content">
                        {{ deUser.idNo }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">手机号</span>
                      <span class="descriptions-item-content">
                        {{ deUser.phone }}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">银行卡号</span>
                      <span class="descriptions-item-content">
                        {{ deUser.bankNo }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">合同类型</span>
                      <span class="descriptions-item-content">
                        {{ {'02': '屋顶租赁', '03': '全款销售', '01': '个人贷款'}[deUser.type] }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">省份</span>
                      <span class="descriptions-item-content">
                        {{ deUser.province }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">组件块数</span>
                      <span class="descriptions-item-content">
                        {{ deUser.size }}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">还款日</span>
                      <span class="descriptions-item-content">
                        {{ deUser.day }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">签订日期</span>
                      <span class="descriptions-item-content">
                        {{ deUser.contractTime }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">并网日期</span>
                      <span class="descriptions-item-content">
                        {{ deUser.signTime }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">备注</span>
                      <span class="descriptions-item-content">
                        {{ deUser.remarks }}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">电站编号</span>
                      <span class="descriptions-item-content">
                        {{ deUser.powerStationCode }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">屋顶类型</span>
                      <span class="descriptions-item-content">
                        {{ deUser.roofTypeModel.name }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">创建时间</span>
                      <span class="descriptions-item-content">
                        {{ deUser.time }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="descriptions">
            <div class="descriptions-header">
              <div class="descriptions-title">收益信息</div>
            </div>
            <div class="descriptions-view">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="4">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">总收益</span>
                      <span class="descriptions-item-content">{{ deUser.sum }}</span>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="descriptions">
            <div class="descriptions-header">
              <div class="descriptions-title">划转信息</div>
            </div>
            <div class="descriptions-view">
              <div v-if="transfer_data" style="width: 100%;box-sizing: border-box;padding-bottom: 50px;">
                <el-tabs v-model="transfer_year" class="demo-tabs">
                  <el-tab-pane v-for="year in transfer_years" :key="year" :label="year" :name="year"></el-tab-pane>
                </el-tabs>
                <div style="padding: 15px;text-align: center">共{{ transfer_data[transfer_year].list.length }}期&nbsp;&nbsp;&nbsp;&nbsp;划转总计：{{ transfer_data[transfer_year].sum }}元</div>
                <el-table
                    border
                    :data="transfer_data[transfer_year].list"
                    style="width: 100%">
                  <el-table-column
                      align="center"
                      prop="date"
                      label="还款日">
                  </el-table-column>
                  <el-table-column
                      align="center"
                      prop="money"
                      label="还款金额（元）">
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"
import {deUserInfo, seeTransferInfo} from "@/api/common"

export default {
  name: "DeUserInfo",
  data() {
    return {
      id: null,
      deUser: null,
      transfer_data: null,
      transfer_years: [],
      transfer_year: '',
    }
  },
  components: {
    PageHeader,
  },
  created() {
    this.id = this.$route.params.id
  },
  mounted() {
    this.getDeUserInfo()
  },
  methods: {
    getDeUserInfo() {
      let that = this
      deUserInfo(that.id).then(res => {
        that.deUser = res
        this.makeTransfer()
      })
    },
    makeTransfer() {
      seeTransferInfo(this.id).then(res => {
        let years = Object.keys(res)
        let sum_index = years.findIndex(item => {
          return item == 'sum'
        })
        if (sum_index >= 0) {
          years.splice(sum_index, 1)
        }
        if (years.length > 0) {
          let transfer_data = res
          for (let year of years) {
            let list = []
            for (let date in transfer_data[year].data) {
              list.push({
                date: date,
                money: transfer_data[year].data[date]
              })
            }
            transfer_data[year].list = list
          }
          this.transfer_data = transfer_data
          this.transfer_years = years
          this.transfer_year = years[0]
        }
      })
    }
  }
}
</script>

<style scoped>

</style>

import { render, staticRenderFns } from "./DeUserInfo.vue?vue&type=template&id=3bb916de&scoped=true"
import script from "./DeUserInfo.vue?vue&type=script&lang=js"
export * from "./DeUserInfo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bb916de",
  null
  
)

export default component.exports